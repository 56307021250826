@import '../../../../styles/vars';

.header {
  background-color: $white;
  flex-shrink: 0;
  height: 84px;
  z-index: 8;
  border-bottom: 1px solid #e4e4e4;
  z-index: 10;
}

.colored {
  border-bottom: 1px solid rgba(255, 255, 255, 0.22);
  background-color: $dark;

  .burger__line {
    background: $white;
  }
  svg path {
    fill: $white;
  }

  .close__button {
    svg path {
      stroke: $white;
    }
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.burger {
  display: none;
}

.close__button {
  display: none;
  svg path {
    stroke: $dark;
  }
}

@media (max-width: 820px) {
  .close__button {
    display: block;
    padding: 0;

    svg {
      width: 36px;
      height: 36px;
      display: block;
    }
  }

  .header {
    height: 70px;
  }

  .header__color {
    background: $white;
  }

  .burger {
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    padding: 8px;
  }

  .burger__line {
    height: 0.125rem;
    background: $dark;
    width: 20px;

    &:first-child {
      margin-top: 2px;
    }

    &:last-child {
      margin-bottom: 2px;
    }
  }
}
