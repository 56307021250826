@import '../../../../styles/vars';

.footerLinksWrapper {
  width: 100%;
  border-top: 1px solid #e4e4e4;
}

.blocks {
  display: flex;
}

.block {
  padding: 4rem 0;
  width: 33.33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: $dark;

  &:not(:last-child) {
    border-right: 1px solid #e4e4e4;

    img,
    p {
      opacity: 0.4;
      transition: opacity 0.4s ease;
    }

    &:hover {

      img,
      p {
        opacity: 1;
        transition: opacity 0.4s ease;
      }

      cursor: pointer;
    }
  }

  &:first-child {
    .block__logo {
      width: 139px;

      img {
        aspect-ratio: 139 / 39;
      }
    }
  }

  &:nth-child(3n + 2) {
    .block__logo {
      width: 129px;

      img {
        aspect-ratio: 129 / 39;
      }
    }
  }

  &:last-child {
    position: relative;

    .block__logo {
      width: 125px;

      img {
        aspect-ratio: 125 / 39;
      }
    }
  }
}

.block__logo {
  img {
    position: relative !important;
    width: auto;
    height: auto;
  }
}

.block__descr {
  text-align: center;
  max-width: 270px;
}

.marker {
  display: flex;
  gap: 7px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 24px;

  p {
    color: $dark;
    text-align: center;
    font-size: 9px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 1.08px;
    text-transform: uppercase;
  }

  .arrow {
    width: 32px;
    transform: rotate(-6.479deg);
    position: absolute;
    right: -36px;
    bottom: 50%;

    img {
      aspect-ratio: 32 / 27;
      position: relative !important;
      width: auto;
      height: auto;
    }
  }
}

@media (max-width: 950px) {
  .wrapper {
    margin: 0;
  }

  .blocks {
    flex-direction: column;
  }

  .block {
    width: 100%;

    &:not(:last-child) {
      border-right: unset;
      border-bottom: 1px solid #e4e4e4;
    }
  }

  .marker {
    bottom: 28px;
  }
}

@media (max-width: 576px) {
  .block {
    &:first-child {
      .block__logo {
        width: 99.8px;
      }
    }

    &:nth-child(3n + 2) {
      .block__logo {
        width: 92.6px;
      }
    }

    &:last-child {
      .block__logo {
        width: 90px;
      }
    }
  }
}

@media (max-width: 376px) {
  .block {
    gap: 0.75rem;
  }
}