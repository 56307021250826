@import '../../../styles/keyframes.scss';

.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.main {
  flex-grow: 1;
  background-color: #fff;
  overflow-x: hidden;
}

.show {
  animation-name: showBlock;
  animation-fill-mode: forwards;
}

.hide {
  animation-name: hideBlock;
  animation-fill-mode: forwards;
}

.scroll__button {
  position: fixed;
  right: 14px;
  bottom: 2rem;
  border-radius: 4px;
  background: rgba(10, 22, 60, 0.05);
  backdrop-filter: blur(7.5px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  cursor: pointer;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;

  &:hover {
    background-color: rgba(10, 22, 60, 0.07);
  }

  &--active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  &--hidden {
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s 0.3s;
  }
}

@keyframes showBlock {
  0% {
    opacity: 0;
    transform: translate(-50%, -40%);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes hideBlock {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -40%);
  }
}

@media (max-width: 1480px) {
  .scroll__button {
    right: 6px;
  }
}

@media (max-width: 1024px) {
  .scroll__button {
    right: 2rem;
  }
}

@media (max-width: 576px) {
  .scroll__button {
    right: 1.5rem;
  }
}

@media (max-width: 414px) {
  .scroll__button {
    right: 1.25rem;
  }
}