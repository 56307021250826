.wrapper {
  margin: 0 auto;
  max-width: 112rem;
}

@media (max-width: 1920px) {
  .wrapper {
    margin: 0rem 4rem;
    max-width: 100%;
  }
}

@media (max-width: 1480px) {
  .wrapper {
    margin: 0rem 3rem;
  }
}

@media (max-width: 1200px) {
  .wrapper {
    margin: 0rem 2.5rem;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    margin: 0rem 2rem;
  }
}

@media (max-width: 576px) {
  .wrapper {
    margin: 0rem 1.5rem;
  }
}

@media (max-width: 414px) {
  .wrapper {
    margin: 0rem 1.25rem;
  }
}
