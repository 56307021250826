@import '../../../../styles/vars';

.footer__bottom {
  width: 100%;
  background: $white;
  flex-shrink: 0;
  background: $white;
  border-top: 1px solid #e4e4e4;
  height: 80px;
}

.footer__branding {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-right: 175.84px;

  p {
    color: rgba(27, 27, 27, 0.7);
    font-size: 12px;
    font-weight: 600;
    line-height: 1;

    a {
      color: $dark;
      text-decoration: underline;
      transition: $anim;

      &:hover {
        color: rgba(27, 27, 27, 0.7);
      }
    }
  }
}

.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.info__links {
  display: flex;
  gap: 0.75rem;

  a {
    color: #5f5f5f;
    font-size: 12px;
    font-weight: 500;
    line-height: 144%;
    position: relative;
    display: block;
    transition: color 0.4s ease-in-out;

    &::after {
      content: '';
      width: 0;
      height: 1px;
      background: #1b1b1b;
      position: absolute;
      left: 0;
      bottom: -2px;
      transition: width 0.4s ease-in-out;
    }

    &:hover {
      color: #1b1b1b;

      &::after {
        width: 100%;
      }
    }
  }
}

.copyright {
  color: rgb(29 29 31 / 65%);
  text-align: right;
  font-size: 12px;
  letter-spacing: -0.02em;
}

.nav {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  &__list {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__item {
    color: $dark;

    a {
      display: flex;
      align-items: center;
      position: relative;
      gap: 0.25rem;
      font-size: 14px;
      font-weight: 500;
      line-height: 120%;
      letter-spacing: -0.01em;
      color: $dark;
      padding: 6px 12px;
      border-radius: 4px;
      transition: background-color 0.4s ease;

      &:hover {
        background-color: #f4f4f4;
      }
    }
  }
}

.bundle {
  font-weight: 600;
}

.currPage {
  a {
    background-color: #f4f4f4;
  }
}

@media (max-width: 1280px) {
  .footer__bottom {
    padding: 24px 0;
    height: auto;
  }

  .footer__branding {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 55px;

    p {
      line-height: 140%;
    }
  }

  .info {
    flex-direction: column;
    align-items: flex-end;
    gap: 0.75rem;
  }
}

@media (max-width: 1200px) {
  .nav {
    &__item {
      a {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav {
    position: relative;
    transform: none;
    left: auto;

    &__list {
      gap: 1rem;
      flex-direction: column;
      margin-bottom: 2rem;
      align-items: flex-start;
    }

    &__item {
      a {
        padding: 6px 8px;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  .currPage {
    &:first-child {
      a {
        padding: 6px 8px;
      }
    }

    a {
      &:hover {
        background-color: #f4f4f4;
      }
    }
  }

  .footer__branding {
    margin-bottom: 2rem;
  }

  .copyright {
    text-align: left;
  }

  .info {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (max-width: 640px) {
  .nav {
    &__list {
      width: 100%;
      justify-content: space-between;
    }
  }
}

@media (max-width: 576px) {
  .nav {
    &__list {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .info {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 414px) {
  .footer__bottom {
    padding: 2rem 0;
  }
}