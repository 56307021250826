@import '../../../../styles/vars';

$text-arrow-space: 4px;
$shaft-width: 1px;
$arrow-width: 20px;
$shaft-thickness: 1px;
$arrow-head-width: 20px;

.btn {
  border: none;
  display: inline-flex !important;
  align-items: center;
  gap: 4px;
  border-radius: 0.25rem;
  height: 50px;
  justify-content: center !important;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-transform: capitalize;
  text-decoration: none !important;
  cursor: pointer;
  width: fit-content;
  transition: $anim;
  position: relative;
  span {
    display: block;
  }
  &.formBtn {
    &--isLoading {
      height: 50px;
      position: relative;
      pointer-events: none;
      padding: 17px 20px;
      .arrow__left,
      .arrow__right {
        display: none;
      }
      .animated__text {
        width: 30px;
        height: 30px;
        margin: 0;
        position: relative;
      }
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
      }
    }
  }
}

.standard {
  padding: 17px 28px;
}

.animated {
  padding: 17px 20px 17px 28px;
  overflow: hidden;
  &:hover {
    .animated__wrapper {
      transform: translateX($arrow-width + $text-arrow-space);
    }
  }
}

.animated__wrapper {
  transition: transform 0.5s cubic-bezier(0.37, 0.72, 0.51, 1.16);
  position: relative;
}

.animated__container {
  overflow: hidden;
}

.arrow {
  width: $arrow-width;
  height: 20px;
  position: absolute;
  top: 50%;

  &__right {
    right: 0;
    left: auto;
    transform: translateY(-50%);
  }
  &__left {
    left: 0;
    transform: translateX(-$arrow-width - $text-arrow-space) translateY(-50%);
    width: $arrow-width;
  }
}

.animated__text {
  margin: 0 calc($arrow-width + $text-arrow-space) 0 0;
}

.primary-light {
  background: $white;
  color: $dark;
  transition: background 0.3s ease-in-out;
  .arrow {
    svg {
      fill: none;
      path {
        stroke: $dark;
      }
    }
  }

  &:hover {
    background: #f8f8f8;
  }
}

.primary-dark {
  background: $dark;
  color: $white;
  transition: background 0.3s ease-in-out;
  .arrow {
    svg {
      fill: none;
      path {
        stroke: $white;
      }
    }
  }

  &:hover {
    background: #2c2c2c;
  }
}

.secondary-dark {
  background: #424242;
  color: $white;
  transition: background 0.3s ease-in-out;
  &:hover {
    background: #2c2c2c;
  }
}

.secondary-light {
  background: #ededed;
  color: $dark;
  transition: background 0.3s ease-in-out;
  &:hover {
    background: #e4e4e4;
  }
}

.clear {
  gap: 2px;
  color: $dark;
  position: relative;
  height: auto !important;
  padding: 0 0 4px !important;
  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 0;
    left: 0;
    bottom: 0;
    background: $dark;
    transition: width 0.3s ease-in-out;
    z-index: 5;
  }
  &:hover {
    &::after {
      width: 100%;
    }
  }
  .arrow {
    svg {
      fill: none;
      path {
        stroke: $dark;
      }
    }
  }
}

@media (max-width: 1200px) {
  .btn {
    padding: 1rem 1.5rem;
    height: 49px;

    &.formBtn {
      &--isLoading {
        height: 53px;
      }
    }
  }
}

@media (max-width: 480px) {
  .btn {
    padding: 1rem 1.25rem;

    &.formBtn {
      width: 100%;
      padding: 17px 28px;
      height: 53px;
    }
  }
}

@media (max-width: 376px) {
  .btn {
    padding: 0.75rem 1rem;
    height: 43px;
  }
}
