@import '../../../../styles/vars';

.logo {
  display: block;

  svg {
    display: block;
    width: 82px;
    height: 22px;
    stroke: none;

    path {
      fill: $dark;
      stroke: none;
    }
  }
}

.footer {
  svg {
    path {
      fill: $dark;
    }
  }
}