@import '../../../../styles/vars';

.nav__list {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.nav__item {
  color: $dark;
  background-color: transparent;

  a {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
    padding: 6px 16px;
    border-radius: 2px;
    letter-spacing: -0.01em;
    color: $dark;
    transition: background-color 0.4s ease;

    &:hover {
      background-color: #f4f4f4;
    }
  }
}

.dark__mode {
  .nav__item {
    color: $white;

    a {
      color: $white;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }

  .currPage {
    a {
      background: rgba(255, 255, 255, 0.1);
    }
  }
}

.currPage {
  a {
    background-color: #f4f4f4;
  }
}

.panel {
  display: none;
}

@media (max-width: 1200px) {
  .nav__item {
    a {
      font-size: 16px;
    }
  }
}

@media (max-width: 820px) {
  .nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 0.1px;
    height: 100vh;
    left: auto;
    right: 0;
    padding: 0;
    border-radius: 0px 0px 8px 8px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
    background-color: $white;
    top: 0;
    transition:
      width 0.4s ease-in-out,
      padding 0.4s ease-in-out;
    z-index: 10;

    &.active {
      width: 100vw;

      // padding: 40px 12px 40px 12px;
      .nav__item {
        display: block;
      }

      .button {
        opacity: 1;
        transition: all 0.4s ease;
        display: inline-flex !important;
      }
    }
  }

  .panel {
    display: block;
    width: 100%;
    border-bottom: 1px solid #e4e4e4;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
  }

  .nav__container {
    padding: 40px 12px;
  }

  .close__button {
    display: block;
    padding: 0;

    svg {
      width: 36px;
      height: 36px;
      display: block;

      path {
        stroke: $dark;
      }
    }
  }

  .dark__mode {
    background: $dark;
    border-bottom: 1px solid rgba(255, 255, 255, 0.22);

    .nav__item {
      a {
        &::after {
          background-color: $white;
        }
      }
    }

    .currPage {
      a {
        background: rgba(255, 255, 255, 0.1);
      }
    }

    .panel {
      border-bottom: 1px solid rgba(255, 255, 255, 0.22);
    }

    .close__button {
      svg path {
        stroke: $white;
      }
    }
  }

  .nav__list {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }

  .nav__item {
    display: none;
    transition: all 0.4s ease;

    a {
      padding: 0 12px;

      &:hover {
        background-color: transparent;
      }
    }

    span:first-child {
      order: 1;
    }
  }

  .button {
    display: none !important;
    opacity: 0;
    transition: all 0.4s ease;
    flex-shrink: 0;
    position: absolute;
    top: 280px;
  }

  .currPage {
    a {
      padding: 6px 12px;

      &:hover {
        background-color: #f4f4f4;
      }
    }
  }
}

@media (max-width: 480px) {
  .nav__list {
    gap: 1.5rem;
  }

  .button {
    width: calc(100vw - 24px);
    top: 270px;
  }
}