.sticky {
  position: sticky;
  top: 0;
  opacity: 1;
  z-index: 12;
  transition:
    top 0.4s ease,
    opacity 0.4s ease;
}

.sticky__hide {
  top: -100px;
  opacity: 0;
  transition:
    top 0.4s ease,
    opacity 0.4s ease;
}
